var _paq = window._paq = window._paq || [];
_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
_paq.push(['trackAllContentImpressions']);
(function () {
	var script = document.querySelector('script[src*="piwik.min"]');
	var jsUrl = script.getAttribute("data-piwik-js-url");
    var phpUrl = script.getAttribute("data-piwik-php-url");
    _paq.push(['setTrackerUrl', phpUrl]);
	_paq.push(['setSiteId', script.getAttribute("data-piwik-id")]);
	var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
	g.type = 'text/javascript';
	g.async = true;
	g.defer = true;
	g.src = jsUrl;
	s.parentNode.insertBefore(g, s);
})();
